body {
  height: 100vh;
}
#root {
  height: 100%;
}
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.App {
  background-color: #fdf5e6; /* Old Lace */
  color: #8b4315; /* Saddle Brown */
  display: flex;
  flex-direction: row;
  position: relative;
  overflow-y: scroll;
  height: 100%;
}
.demo-bg {
  opacity: 0.03;
  background-image: url('/public/banners/dmbs_banner.svg');
  background-repeat: repeat;
  background-position: 0 0;
  background-size: auto ;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.demo-content {
  position: relative;
  width: 100%;
  height: 100%;
}

.App>.section-container-body {
  position: relative;
  width: 100%;
  z-index: 1;
}
.button-action {
  background-color: #8b4513; /* #4681f4; */
  border-color: #3c341f; /* Olive Drab #7 */
  border-radius: 5px;
  border-style: outset;
  border-width: 2px;
  color: #faebd7; /* Moccasin; */
  cursor: pointer;
  display: inline-block;
	font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-weight: bold;
  margin-top: 8px;
  padding: 4px;
}
.button-action:active {
  border-style: inset;
}
.button-action:hover {
  color: #8b4513; /* color: white; */
  background-color: #faebd7; /* color: #228b22; */
  text-decoration: underline;
}
.container-button-action {
	border:3px outset;
}
.container-row {
  display: flex;
  flex-direction: row;
}
.container-col {
  display: flex;
  flex-direction: column;
}
.no-error {
	display:none;
}
.text-input-error {
	display:block;
	color: red;
	font-weight: bold;
	font-size: 1.1em;
}
.state-select {
	/* color: #777; */
	color: black;
	font-size: 1em;
	font-weight: normal;
}
.state-select option {
}
.state-select-option {

}
.text-align-right {
	text-align: right;
}
.soft-border {
	border: 1px solid #aaa;
	border-radius: 5px;
}
.shadow-bottom-right {
	box-shadow: 3px 3px 3px #aaa;
}
.form-label {

}
.form-title {
  align-items:flex-end;
  font-size:1.25em;
  font-weight:700;
  margin-right:1em;
  white-space:nowrap
}
.form-data {

}
.form-item-container {
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  width: 375px;
}
.section-container {

}
.required-item {
  color: red;
}
/* Body */
.section-container-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.section-container-body div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.section-container-body div>p {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 75%;
}
.section-container-body div>.action-text {
  color: #1468a1;
  cursor: pointer;
  text-decoration: underline;
}
.section-container-body div>.inline-message {
  flex-direction: row !important;
  justify-content: flex-start !important;
}
.container-bible-study-request-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.container-bible-study-request-login-message {

}
.bible-study-request-form-title {
  font-size: 1.25em;
  font-weight: bold;
  text-decoration: underline;
}
.container-bible-study-request-content {

}
.container-bible-study-request-action {

}
.submit-bible-study-request {

}
.container-button-action-submit-bible-study-request {

}
.radiogroup-bible-study-request {
  margin-top: 2px;
  margin-bottom: 2px;
}
.radioradio-bible-study-request {

}
.bible-study-request-topic {

}
.container-bible-study-request-topic {
  margin-top: 2px;
  margin-bottom: 2px;
}
.bible-study-request-desc {

}
.container-bible-study-request-desc {
  margin-top: 2px;
  margin-bottom: 2px;
}
/* Comment */
.comment-text {
  width: 50%;
}
.comment-info {
  display: flex;
  flex-direction: column;
}
.comment-username {
  font-size: .75em;
  font-weight: bold;
  margin-right: 2px;
}
.comment-datecreated {
  font-size: .5em;
  font-style: italic;
  margin-left: 2px;
}
.comment-action {
  display:flex;
  flex-direction: column;
}
.reply-content-action {

}
.container-comment {
  border-bottom: 2px dashed #8b5314;
  display: flex;
  margin-bottom: 3px;
  padding-bottom: 3px;
}
.button-comment-reply-cancel {
  font-size: .75em;
}
.button-comment-reply {
  font-size: .75em;
}
.button-comment-update {
  font-size: .75em;
}
.button-comment-delete {
  font-size: .75em;
}
.button-comment-replies-show {
  font-size: .75em;
}
.comment-likes-button {
  font-size: .5em;
  margin-left: 2px;
  padding: 1px;
  padding-left: 3px;
  padding-right: 3px;
}
/* CommentForm */
.container-comment-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: 10px;
}
.container-comment-form>div:first-child {
  width: 100%;
}
.container-comment-form input {
  width: 100%;
}
.button-comment-submit {

}
.button-comment-submit-cancel {

}
/* CommentList */
.button-comments-hide {

}
.container-comments-list {
  border-left: 2px solid #8b4513;
  border-bottom: 2px solid #8b4513;
  padding-left: 5px;
  padding-bottom: 5px;
}
/* Community */
.container-community {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.container-community-controls {
  display:flex;
  flex-direction:row;
  justify-content: center;
  align-self: center;
  align-content: center;
  align-items: center
}
.container-community-controls>div{
  margin: 2px;
  padding: 2px;
}
.button-show-rules {
  font-size: .75em;
}
.button-show-subscribe {
  font-size: .75em;
}
.community-selector-style {

}
.community-selector-item-style {

}
.community-selector-container-style {

}
.container-community-conversation-list {
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-self: center;
  align-content: center;
  align-items: center;
  width: 96%;
}
.container-community-record-pagination-control {

}
.community-conversation-add-button {
  font-size: .9em;
  width: 130px;
}
/* CommunitySubscription */
.container-community-subscription {
  align-self:center;
  display: flex;
  flex-direction: column;
}
.community-subscription-title {
  font-size: 1.15em;
  font-weight: bold;
  text-decoration: underline;
}
.community-subscription-subscribe-button-container {
  align-self: center;
}
.community-subscription-close-button-container {
  align-self: center;
}
.button-community-subscribe {

}
.button-community-subscribe-close {

}
/* Conversation */
.container-conversation {
  margin-bottom: 15px;
  border-left: 3px solid #8b4513;
  border-bottom: 3px solid #8b4513;
  padding-bottom: 5px;
  padding-left: 5px;
}
.container-conversation-title {

}
.conversation-title {
  font-weight: bold;
  font-size: 1.15em;
}
.container-conversation-likes {

}
.conversation-likes-button {
  font-size: .5em;
  margin-left: 2px;
  padding: 1px;
  padding-left: 3px;
  padding-right: 3px;
}
.container-conversation-details {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.container-conversation-content {

}
.conversation-content {

}
.conversation-content-action {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}
.conversation-content-action>div {
  margin-left: 1px;
  margin-right: 1px;
}
.button-conversation-details-show {

}
.button-conversation-delete {

}
.button-conversation-update {

}
.button-conversation-close {

}
.buttonconversation-comment {

}
.button-conversation-comments-show {

}
.container-conversation-date-created {
  font-style: italic;
  font-size: .85em;
}
.conversation-closed-message {

}
/* ConversationForm */
.button-conversation-submit {
  width: 100px;
}
.button-conversation-cancel {
  width: 100px;
}
.container-conversation-form {

}
/* ConversationList */
.container-conversation-list-component {
  align-self: center;
  width: 100%;
}
.container-conversation-list-component>div {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
/* Reply */
.container-reply-main {
  background-color: #fdf5e6;
  border: 1px solid #8b4315;
  padding: 2px;
  margin: 2px;
}
.reply-text {

}
.reply-username {

}
.reply-datecreated {

}
.button-reply-update {

}
.button-reply-update-cancel {

}
.button-reply-delete {

}
.reply-likes-button {
  font-size: .5em;
  margin-left: 2px;
  padding: 1px;
  padding-left: 3px;
  padding-right: 3px;
}
/* ReplyForm */
.container-reply-form-main {

}
.button-reply-submit {

}
/* ReplyList */
.container-replies-list {
  /* border-left: 1px solid #8b4513; */
}
.button-replies-hide {

}
/* Rule */
.container-rule {

}
.container-button-rule-update {
  padding-left: 15px;
}
.container-form-rule-update {

}
.rule-update-form-order-num {

}
.container-button-rule-delete {
  padding-left: 2px;
}
.container-rule-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.rule-order-num-display {
  border:2px solid #8b4513;
  border-radius: 7px;
  font-weight: bold;
  padding-left: 1px;
  padding-right: 1px;
  margin-right: 2px;
}
.rule-text-display {
  padding-left: 1px;
  padding-right: 1px;
}
.button-rule-update {

}
.button-rule-delete {

}
.button-rule-update-submit {

}
.button-rule-update-cancel {

}
.rule-label-class {

}
/* Rules */
.container-section-rules {
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
	position: fixed;
  top: 0;
	width:100%;
}
.rules-section {
  background: white;
  border: 3px solid #8b4315;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: auto;
  left:50%;
  padding: 3px;
  position:fixed;
  top:50%;
  transform: translate(-50%,-50%);
  width: 80%;
}
.container-section-rules-children {
  align-content: flex-start;
	align-items: flex-start;
  align-self: center;
	display:inline-flex;
	flex-direction: column;
}
.container-button-rules-close {
  align-self: center;
}
.rule-add-button-container {
  align-self: center;
}
.button-rules-create {

}
.button-rules-create-submit {

}
.button-rules-create-cancel {

}
.button-rules-close {

}
/* Courses */
.container-courses-main {
  width: 100%;
}
/* Footer */
.container-footer-main {
	background-color: #cd853f; /* peru */
  border:1px solid;
  border-image-source: url('/public/borders/dmbs_border.svg');
  border-image-outset: 0px;
  border-image-repeat: round;
  border-image-slice: 19 12;
  border-image-width: 20px;
  padding-top: 26px;
  padding-bottom: 26px;
	display: flex;
	color: #f5deb3;
	flex-direction: row;
	font-weight: bold;
	justify-content: space-around;
}
.small-copyright {
	font-size: .75em;
}
.footer-title {
	font-size: .75em;
}
.footer-menu-container {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}
.footer-menu-item-container {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}
.footer-menu-item {
	display: inline-block;
	border-style: outset;
	cursor: pointer;
	border-width: 1px;
	border-radius: 3px;
	background-color: #8b4513;
	color: #f5deb3;
  font-weight: bold;
	padding: 2px;
	font-size: .75em;
	margin-left: 2px;
	margin-right: 2px;
}
.footer-menu-item:hover {
	color: #fdf5e6;
	text-decoration: underline;
}
.footer-menu-item:active {
	border-style: inset;
}
/* MainNavigationControl */
.container-control-main-navigation {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.container-control-main-navigation>h3 {
	text-align: center;
}
.navigation-button-container {
  align-self: center;
}
.navigation-button {
	display: inline-block;
  border-style: outset;
  cursor: pointer;
  border-color: #3c341f;
	border-width: 1px;
	border-radius: 3px;
	background-color: #8b4513;
	color: #faebd7;
	font-weight: bold;
	padding: 2px;
	font-size: 1em;
	margin-top: 2px;
	margin-bottom: 1px;
  text-align: center;
  width: 212px;
}
.navigation-button:hover {
	color: #fdf5e6;
	text-decoration: underline;
}
.navigation-button:active {
	border-style: inset;
}
/* UserManagementControl */
.container-control-user-management {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}
.container-control-user-management-button-container {

}
.avatar {

}
/* Header */
.container-header {
	background-color: #cd853f;
	border: 1px solid #cd853f;
	color: #f5deb3;
	display: flex;
	flex-direction: row;
}
.container-header-middle-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  border:1px solid;
  border-image-source: url('/public/borders/dmbs_border.svg');
  border-image-outset: 0px;
  border-image-repeat: round;
  border-image-slice: 19 12;
  border-image-width: 20px;
  padding: 26px;
}
/* .container-header-background {
  position:absolute;
  background-image: url("/public/banners/dmbs_banner.svg");
  opacity:0.6;
  left:0;
  top:0;
  background-size: contain;
  width:100%;
} */
.site-identifier {
	display: flex;
	flex-direction: row;
  font-size: 2em;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.navigation {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
  width: 100%;
}
.navigation-main {

}
.navigation-user-management {

}
.container-header-middle-section {
  display: flex;
  flex-direction: column;
  align-content: center;
}
.container-header-bible-study-request-button-container {
  display: flex;
  flex-direction: column;
}
.container-control-bible-study-navigation {
  display: flex;
  flex-direction: column;
}
.container-bible-study-request-login-message {
  text-align: center;
  font-weight: bold;
}
/* Login */
.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.href-link {
  text-decoration: underline;
  color:#1468a1;
  font-weight: bold;
  font-size: .96em;
}
.href-link:hover {
  cursor: pointer;
  color:blue;
}
.create-new-user-link-container {
  margin: 15px;
  padding:15px;
}
.login-messages {
  display: inline-flex;
  align-self: center;
  color: red;
  font-weight: bold;
  font-size: 2em;
  margin: 15px;
  padding:15px;
}
.container-button-submit-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}
/* Store */
.container-store-main {
  width: 100%;
}
/* PasswordInfo */
.container-password-info-main {
  display:flex;
  flex-direction:column;
  /* justify-content: center;
  align-self: center;
  align-content: center;
  align-items: center; */

  justify-content: flex-start;
  align-items: flex-start;
}
/* PasswordReset */
.button-password-reset-submit {

}
.button-password-reset-cancel {

}
.container-password-reset {
  width: 100%;
}
.container-password-reset-new {

}
.container-password-reset-confirm {

}
/* PersonalInfo */
.container-personal-info-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container-personal-info-name {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.container-personal-info-birthdate {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.container-birthdate {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.container-personal-info-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container-phone {
  align-items: center;
  align-content: center;
  align-self: center;
  display: flex;
  flex-direction: row;
  flex: 1 1 0%;
  place-content: flex-end;
}
/* Profile */
.container-profile-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.container-profile-action-buttons {
  display:flex;
  flex-direction:row;
  justify-content: center;
  align-items: center
}
.button-action-profile {
  margin-left: 2px;
  margin-right: 2px;
}
.button-code-check-submit {
  width: 125px;
  text-align: center;
}
.button-code-check-submit-container {

}
/* StudySelection */
.container-study-selection {
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-self: center;
  align-content: center;
  align-items: center
}
/* UserAddress */
.container-user-address {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
/* .container-address-info-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */
/* UserContact */
.container-user-contact {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
/* Email Address */
.container-email-address {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
/* Name */
.container-name-object {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
/* UserName */
.container-user-name {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.container-phone-number {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.container-birthdate {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
/* Likes */
.page-element-likes-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
}
.page-element-likes-container>div:first-child {
  font-size: .7em;
}
.page-element-button {

}
/* Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.section-modal-main {
  position: fixed;
  background: white;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border: 4px solid #8b4513;
  border-radius: 10px;
}
.button-modal {
  margin: 5px;
}
.modal-button-close-container {
  font-size: .75em;
  display: flex;
}
/* RecordLimitSelector */
.limit-selector {

}
.limit-selector-style {

}
.limit-selector-item-style {

}
.limit-selector-container-style {

}
/* RecordPaginationControl */
.pagination-control {
	border: 1px solid brown;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 2px;
}
.pagination-control-first-container {
  margin: 0px;
  padding: 0px;
}
.pagination-control-prev-container {
  margin-left: 1px;
  margin-right: 1px;
  padding: 0px;
}
.pagination-control-next-container {
  margin-left: 1px;
  margin-right: 1px;
  padding: 0px;
}
.pagination-control-last-container {
  margin: 0px;
  padding: 0px;
}
.pagination-button {
	background-color: #8b4513;
  border-color: #3c341f;
  border-radius: 5px;
  border-style: outset;
  border-width: 2px;
  color: #faebd7;
  cursor: pointer;
  display: inline-block;
	font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-weight: bold;
  margin-top: 8px;
  padding: 4px;
  text-decoration: underline;
}
.pagination-button:hover {
	background-color: #faebd7;
	color: #8b4513;
}
.pagination-button-disabled {
  background-color: #8b4513;
  border-color: #8b4513;
  border-radius: 5px;
  color: #8b4513;
  cursor: auto;
}
.pagination-button-disabled:hover {
  background-color: #8b4513;
  border-color: #8b4513;
  border-radius: 5px;
  color: #8b4513;
  cursor: auto;
}
.pagination-button-disabled:active {
  background-color: #8b4513;
  border-color: #8b4513;
  border-radius: 5px;
  border-style: outset;
  color: #8b4513;
  cursor: auto;
}
.pagination-control-goto-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pagination-control-goto-select {

}
.pagination-control-goto-option {

}
/* RecordSortSelector */
.sort-selector {

}
.sort-selector-style {

}
.sort-selector-item-style {

}
.sort-selector-container-style {

}
.display-block {
  display: block;
}
.display-flex {
  display: flex;
}
.display-none {
  display: none;
}
.button-disabled {
  display: none;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .state-select {
		width: 96%;
	}
  .container-control-main-navigation>h3 {
    font-size: .75em;
    white-space: nowrap;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 767px) {
  .container-control-main-navigation>h3 {
  	font-size: 1em;
    white-space: nowrap;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-device-width: 1199px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}
